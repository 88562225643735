var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dark_overlay
        ? _c("div", { staticClass: "over-lay", on: { click: _vm.maskClose } })
        : _vm._e(),
      _c("ValidationProvider", {
        class: "col-span-4 relative",
        attrs: {
          vid: _vm.fieldId,
          rules: _vm.rules,
          name: _vm.fieldName,
          mode: _vm.mode,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("vue-tags-input", {
                  staticClass: "genre-field",
                  class: {
                    "error_genre-field": errors.length,
                    "z-20": _vm.dark_overlay,
                  },
                  attrs: {
                    placeholder: "",
                    tags: _vm.getSelectedGenres(),
                    "autocomplete-min-length": 0,
                    "add-only-from-autocomplete": true,
                    "autocomplete-items": _vm.genreTagList,
                    "avoid-adding-duplicates": true,
                  },
                  on: {
                    "tags-changed": _vm.genreSelectionChanged,
                    focus: function ($event) {
                      return _vm.maskOpen()
                    },
                  },
                  model: {
                    value: _vm.tagPlaceholder,
                    callback: function ($$v) {
                      _vm.tagPlaceholder = $$v
                    },
                    expression: "tagPlaceholder",
                  },
                }),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: errors.length,
                        expression: "errors.length",
                      },
                    ],
                    staticClass:
                      "text-xxs text-left text-red-600 absolute w-56",
                  },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }